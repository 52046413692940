@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4e2e0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilo personalizado para o botão de idioma */
.btn-outline-primary {
  color: #ffffff; 
  background-color: #749196; 
  border-color: #749196; 
}

.btn-outline-primary:hover {
  background-color: #5f797c; 
  border-color: #5f797c; 
  color: #ffffff; 
}